import React, { useEffect, useState, useRef } from "react";
import io from "socket.io-client";
// ANT DESIGN COMPONENTS
import { Button, Tag, Card } from "antd";
// REDUX
import { useSelector } from "react-redux";
import { usePostEmulatorCommandMutation } from "../../../redux/monitoring/monitoringAPI";
// AUTHENTICATION
import GETJwtToken from "../../../redux/authentication/authentication";

const MonitoringWebSocketConnectionEmulator = ({ add, remove, size, clear, readFrequency, serverName }: any) => {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const SOCKET_URL = process.env.REACT_APP_WEB_SOCKET_EMULATOR || "";
	const myInterval: any = useRef(null);
	const myIntervalRefreshQueue: any = useRef(null);

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [newSocketClient, setNewSocketClient] = useState<any>(undefined);
	const [isConnected, setIsConnected] = useState(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { showQueueInfo } = useSelector((state: any) => state.monitoring);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [postCommandEmulator] = usePostEmulatorCommandMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onStartInterval = () => {
		myInterval.current = setInterval(() => {
			remove();
		}, readFrequency);
	};

	const onStopInterval = () => {
		clearInterval(myInterval.current);
	};

	const onStartIntervalRefreshQueue = () => {
		myIntervalRefreshQueue.current = setInterval(() => {
			clear();
			// eslint-disable-next-line no-console
			console.info("Clear Queue");
		}, 1000 * 60 * 2);
	};

	const onStopIntervalRefreshQueue = () => {
		clearInterval(myIntervalRefreshQueue.current);
	};

	const onConnect = () => {
		setIsConnected(true);
		const SOCKET = io(SOCKET_URL, {
			path: "/simulator",
			reconnectionDelayMax: 3000,
			reconnectionAttempts: 2,
		});
		clear();
		setNewSocketClient(SOCKET);
		onStartInterval();
		onStartIntervalRefreshQueue();
	};

	const onMessage = () => {
		if (newSocketClient) {
			newSocketClient.on("message", (data: any) => {
				if (data) {
					add(data);
				}
			});
		}
	};

	const onDisconnect = () => {
		setIsConnected(false);
		setNewSocketClient(undefined);
		clear();
		onStopInterval();
		onStopIntervalRefreshQueue();
		if (newSocketClient) {
			newSocketClient.disconnect();
		}
	};

	const onResetEmulator = async () => {
		clear();
		const token = await GETJwtToken();
		const BODY = {
			token,
			body: {
				type: "RESET",
				lift_id: "",
				floor: 0,
				bank_id: "",
				front_rear: "",
			},
			serverId: "",
		};
		postCommandEmulator(BODY);
	};
	// ************************************************* */
	// USE EFFECT ************************************** */

	useEffect(() => {
		onConnect();
		clear();
		return () => {
			clear();
			onDisconnect();
		};
	}, []);

	useEffect(() => {
		if (newSocketClient) {
			onMessage();
		}
	}, [newSocketClient]);

	// ************************************************ */
	// COMPONENT ************************************** */

	return (
		<div>
			{showQueueInfo && (
				<div>
					<Card
						title={serverName}
						style={{ width: 400 }}
						extra={
							<div>
								{!isConnected && (
									<Button onClick={onConnect} disabled={isConnected}>
										Connect
									</Button>
								)}
								{isConnected && (
									<Button onClick={onDisconnect} disabled={!isConnected}>
										Disconnect
									</Button>
								)}
								<Button className='generalStyles__mlFix' onClick={onResetEmulator} disabled={!isConnected}>
									Reset Emulator
								</Button>
							</div>
						}
					>
						<p style={{ marginBottom: "-1px" }}>
							<b className={`${isConnected ? "generalStyles__info" : "generalStyles__warning"}`}>Status:</b>{" "}
							<b style={{ color: "white" }}>
								{isConnected && <Tag color='green'>OPEN</Tag>}
								{!isConnected && <Tag color='red'>CLOSED</Tag>}
							</b>
						</p>
						<p style={{ marginBottom: "-1px" }}>
							<b className={`${isConnected ? "generalStyles__info" : "generalStyles__warning"}`}>Socket Connection:</b>{" "}
							<span>EMULATOR</span>
						</p>
						<p style={{ marginBottom: "-1px" }}>
							<b className={`${isConnected ? "generalStyles__info" : "generalStyles__warning"}`}>Queue Messages:</b>
							<span>{size}</span>
						</p>
						<p style={{ marginBottom: "-1px" }}>
							<b className={`${isConnected ? "generalStyles__info" : "generalStyles__warning"}`}>Read Frequency:</b>
							<span>{readFrequency}ms</span>
						</p>
					</Card>
				</div>
			)}
		</div>
	);
};
export default MonitoringWebSocketConnectionEmulator;
